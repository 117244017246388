<template>
  <div class="material">
    <TopBar :type="2" :pageTitle="'个人资料'" />
    <!-- <label class="replace">更换</label> -->
    <div class="form">
      <div class="form-box avatar">
        <span class="form-title">头像</span>
        <div class="user-img">
          <input class="file-input" type="file" name="upload" accept="image/gif,image/jpeg,image/jpg,image/png" @change="fileChange">
          <img v-if="showAvatar!=''" :src="showAvatar">
          <img v-else :src="require('@/assets/head-default-personal.png')">
        </div>
      </div>
      <router-link to="/userCenter/edit/1" class="form-box">
        <span class="form-title">用户昵称</span>
        <span class="form-text">{{userInfo.nickname}}</span>
      </router-link>
      <router-link to="/userCenter/edit/2" class="form-box">
        <span class="form-title">真实姓名</span>
        <span class="form-text">{{userInfo.name!=''&&userInfo ? userInfo.name : userInfo.phone}}</span>
      </router-link>
      <div class="form-box company">
        <span class="form-title">所在公司</span>
        <span class="form-text">{{userInfo.company_name}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'Material',
  data(){
    return {
      showAvatar: '', //头像
      newName: '',
      newNickname: ''
    }
  },
  components: {
    TopBar
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    this.getUserInfo1();//获取用户信息
    
    if(this.userInfo){
      this.newName = this.userInfo.name;
      this.newNickname = this.userInfo.nickname;
    }
  },
  methods: {
    ...mapMutations(['ShowTipModal', 'GetUserInfo']), //tip弹窗
    //获取用户信息
    getUserInfo1(){
      this.$axios.post(`/v1/personal/userInfo`, {}, {useLog: true}).then(res => {
        if(res.code == 0){
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.GetUserInfo();
          this.showAvatar = this.userInfo.avatar;
        }
      })
    },
    //更换头像
    fileChange(e){
      let _this = this;
      let file = e.target.files[0];
      let reader = new FileReader();

      reader.onload = function(e){
          _this.showAvatar = e.target.result;
          _this.subBtn();
      }
      reader.readAsDataURL(file);
    },
    //保存
    subBtn(){
      let data = {}

      if(!this.showAvatar.includes('http')){
        data.avatar = this.showAvatar;
      }

      this.$axios.post(`/v1/personal/updateInfo`, data, {useLog: true}).then(res => {
        if(res.code == 0){
          let _this = this;
          this.ShowTipModal({
            text: '修改成功',
            fun: function(){
              _this.getUserInfo1();
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.img-box{
  width: 130px;
  margin-bottom: 20px;
  position: relative;
}
.user-img{
  width: 98px;
  height: 98px;
  border-radius: 50%;
  overflow: hidden;
  float: none;
  img{
    display: block;
    width: 100%;
    height: 100%;
  }
  .file-input{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
  }
}
.form{
  padding: 0 28px;
}
.form-box{
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 48px;
  position: relative;
  &::after{
    content: '';
    display: block;
    width: 38px;
    height: 38px;
    background: url(~@/assets/arrows.png) right center no-repeat;
    background-size: auto 100%;
    transform:rotate(-90deg);
    position: absolute;
    right: -12px;
    top: 50%;
    margin-top: -19px;
  }
  &.avatar,
  &.company{
    padding-right: 0;
    &::after{
      display: none;
    }
  }
  .form-title{
    font-size: 28px;
    color: #999;
  }
  .form-text{
    border: none;
    padding: 0;
    font-size: 26px;
    color: #333;
  }
}
</style>
